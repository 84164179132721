// src/ContactUs.js
import React from 'react';

const ContactUs = () => {
  return (
    <div className="flex flex-col items-center p-8 bg-gray-200 min-h-screen">
      <main className="w-full max-w-3xl bg-white shadow-lg rounded-xl p-8">
        <section className="text-center">
        <h2 className="text-3xl font-bold text-black mb-8">
            تواصل معنا عبر وسائل التواصل الاجتماعي
          </h2>
          <div className="flex flex-wrap justify-center gap-20">
            <a
              href="https://www.facebook.com/profile.php?id=100069373024430&mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center text-gray-700 transition-transform transform hover:scale-110 hover:text-teal-500"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
                alt="Facebook Logo"
                className="w-16 h-17 mb-2 transition-transform transform hover:scale-125"
              />
              <span className="text-base font-medium">فيسبوك</span>
            </a>
            <a
              href="https://www.tiktok.com/@malllineapp?_t=8oVKhiNRhhz&_r=1"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center text-gray-700 transition-transform transform hover:scale-110 hover:text-black"
            >
              <img
                src="https://i.pinimg.com/736x/a0/ab/5a/a0ab5a71afd487d1cc3eb185bf2c5d88.jpg"
                alt="TikTok Logo"
                className="w-16 h-14 mb-2     transition-transform transform hover:scale-125"
              />
              <span className="text-base font-medium">تيك توك</span>
            </a>

            <a
              href="mailto:mallline.org@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center text-gray-700 transition-transform transform hover:scale-110 hover:text-red-500"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png"
                alt="Gmail Logo"
                className="w-16 h-17 mb-2 transition-transform transform hover:scale-125"
              />
              <span className="text-base font-medium">البريد الالكتروني</span>
            </a>
            <a
              href="https://www.instagram.com/app.mallline"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center text-gray-700 transition-transform transform hover:scale-110 hover:text-pink-500"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
                alt="Instagram Logo"
                className="w-16 h-17 mb-2 transition-transform transform hover:scale-125"
              />
              <span className="text-base font-medium">انستجرام</span>
            </a>
            <a
              href="https://x.com/mallline_app"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center text-gray-700 transition-transform transform hover:scale-110 hover:text-black"
            >
              <img
                src="https://i.pinimg.com/474x/9d/43/bd/9d43bdd7d64940692337530eb907ce47.jpg"
                alt="Twitter Logo"
                className="w-16 h-15 mb-2 transition-transform transform hover:scale-125"
              />
              <span className="text-base font-medium">اكس</span>
            </a>
           
            <a
              href="https://www.youtube.com/channel/UC2Ggv7Zs-V_B6ONPKhgzamg"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center text-gray-700 transition-transform transform hover:scale-110 hover:text-red-500"
            >
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png"
                alt="YouTube Logo"
                className="w-16 h-16 mb-2 transition-transform transform hover:scale-125"
              />
              <span className="text-base font-medium">يوتيوب</span>
            </a>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContactUs;
