// src/components/Footer.js
import React from 'react';
import '../../src/App.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-[#00B3D3] text-white p-4">
    <div className="container mx-auto text-center">
    <span className="mr-1">&#169;</span> {t('copyright')} {/* Unicode copyright sign */}
    </div>
  </footer>
  );
};

export default Footer;
