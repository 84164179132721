// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import MainSection from './components/MainSection';
import Store from './pages/Store';
import Contact from './pages/Contact'; 
import Privacy from './pages/Privacy'; 
import PaymentSuccess from './pages/payment-status/success';
import PaymentFailed from './pages/payment-status/fail';
import PaymentPending from './pages/payment-status/pending';

import './App.css';

const App = () => {
  return (
    <Router>
      <ConditionalHeader />
      <Routes>
        <Route path="/" element={<MainSection />} />
        <Route path="/store" element={<Store />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/payment-success" element={<PaymentSuccess />} /> 
        <Route path="/payment-failed" element={<PaymentFailed />} /> 
        <Route path="/payment-pending" element={<PaymentPending />} /> 
      </Routes>
      <Footer />
    </Router>
  );
};

// Component to conditionally render Header
const ConditionalHeader = () => {
  const location = useLocation();
  const shouldHideHeader = location.pathname.includes('/payment-success') || location.pathname.includes('/payment-failed') || location.pathname.includes('/payment-pending');
  
  return (
    !shouldHideHeader && <Header />
  );
};

export default App;
