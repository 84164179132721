// src/components/MainSection.js
import React from 'react';
import '../../src/App.css';
import 'animate.css';
import { useTranslation } from 'react-i18next';

import appStore from '../components/assets/images/appStore.png'; 
import googlePlay from '../components/assets/images/googlePlay.png'; 
import mallline_shopping_home from '../components/assets/images/mallline_shopping_home.png'; 
import stores_screen from '../components/assets/images/stores_screen.png'; 
import product_details from '../components/assets/images/product_details.png'; 
import store_UI from '../components/assets/images/store_UI.png'; 
import orders_center from '../components/assets/images/orders_center.png'; 
import clups from '../components/assets/images/clups.png'; 
import chatline from '../components/assets/images/chatline.png'; 

const MainSection = () => {
  const { t, i18n } = useTranslation();
  const dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <main dir={dir}>
      <div className="hero-section">
        <h1 className="animate__animated animate__fadeInDown">{t('First E-Mall')}</h1>
        <p className="animate__animated animate__fadeInUp">{t('Best Shopping Experience')}</p>
        <div className="app-buttons">
          <a href="#"><img src={appStore} alt={t('Download from App Store')} /></a>
          <a href="#"><img src={googlePlay} alt={t('Get it from Google Play')} /></a>
        </div>
        <div className="phone-image">
          <div className="phone-frame animate__animated animate__zoomIn">
            <img src={product_details} alt={t('Product Details on App')} />
            <div className="image-text">
              <h3>{t('Product')}</h3>
              <p>{t('Comprehensive Product Details')}</p>
            </div>
          </div>
          <div className="phone-frame animate__animated animate__zoomIn">
            <img src={mallline_shopping_home} alt={t('Shopping Home on App')} />
            <div className="image-text">
              <h3>{t('Shopping')}</h3>
              <p>{t('Easy-to-Use Shopping Interface')}</p>
            </div>
          </div>
          <div className="phone-frame animate__animated animate__zoomIn">
            <img src={store_UI} alt={t('Store UI on App')} />
            <div className="image-text">
              <h3>{t('Store')}</h3>
              <p>{t('Browse Various Stores with Modern UI')}</p>
            </div>
          </div>
        </div>
      </div>

      <section className="feature-section">
        <div className="feature-image">
          <img src={mallline_shopping_home} alt={t('Shopping Demo')} className="animate__animated animate__fadeInRight"/>
        </div>
        <div className="feature-details">
          <h3 className="animate__animated animate__fadeInRight">{t('Shopping Experience')}</h3>
          <p className="animate__animated animate__fadeInRight">{t('Enjoy a unique and easy shopping experience through many different stores and a variety of products, all through the Mallline app.')}</p>        
        </div>
      </section>

      <section className="feature-section">
        <div className="feature-details">
          <h3 className="animate__animated animate__fadeInLeft">{t('Various Stores')}</h3>
          <p className="animate__animated animate__fadeInLeft">{t('Browse a lot of diverse stores with a comfortable and easy-to-use interface, and become a customer at all your favorite stores to receive all exclusive offers and new products.')}</p>
        </div>
        <div className="feature-image">
          <img src={stores_screen} alt={t('Stores Demo')} className="animate__animated animate__fadeInLeft"/>
        </div>
      </section>

      <section className="feature-section">
        <div className="feature-image">
          <img src={orders_center} alt={t('Order Center')} className="animate__animated animate__fadeInRight"/>
        </div>
        <div className="feature-details">
          <h3 className="animate__animated animate__fadeInRight">{t('Order Center')}</h3>
          <p className="animate__animated animate__fadeInRight">{t('Track all your orders from various stores you deal with through an easy-to-use interface that informs you about the status of your orders, whether they are accepted or pending, and also confirms receipt of your orders.')}</p>
        </div>
      </section>

      <section className="feature-section">
        <div className="feature-details">
          <h3 className="animate__animated animate__fadeInLeft">{t('Chat Line')}</h3>
          <p className="animate__animated animate__fadeInLeft">{t('Communicate with all the stores you want to deal with through Mallline’s chat line.')}</p>
        </div>
        <div className="feature-image">
          <img src={chatline} alt={t('Chat Line')} className="animate__animated animate__fadeInLeft"/>
        </div>
      </section>

      <section className="feature-section">
        <div className="feature-image">
          <img src={clups} alt={t('Shopping Clubs')} className="animate__animated animate__fadeInRight"/>
        </div>
        <div className="feature-details">
          <h3 className="animate__animated animate__fadeInRight">{t('Shopping Clubs')}</h3>
          <p className="animate__animated animate__fadeInRight">{t('You can now shop with your friends together through shopping clubs. Create your own shopping club and send the link to all your friends to join and shop together.')}</p>
        </div> 
      </section>
    </main>
  );
};

export default MainSection;
