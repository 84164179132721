// src/pages/stores.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'animate.css'; // Import Animate.css if you haven't already

// Import images correctly
import easySetupImg from '../pages/images/store.png';
import customizableDesignImg from '../pages/images/online-shop.png';
import supportImg from '../pages/images/24-hours-support.png';
import secureTransactionsImg from '../pages/images/locked-card.png';
import analyticsToolsImg from '../pages/images/diagnostic.png';
import affordablePricingImg from '../pages/images/money.png';
import noFeeImg from '../pages/images/no-money.png';
import addProductsImg from '../pages/images/add.png';
import addOffersImg from '../pages/images/offer.png';

// Step icons
import step1Icon from '../pages/images/id-card.png';
import step2Icon from '../pages/images/shop.png';
import step3Icon from '../pages/images/franchise.png';
import step4Icon from '../pages/images/product.png';
import step5Icon from '../pages/images/paper.png';
import step6Icon from '../pages/images/profit-up.png';

// New icons for subscription policies
import subscriptionIcon from '../pages/images/subscription.png';
import sellIcon from '../pages/images/sell.png';
import offerIcon from '../pages/images/offer.png';

import privateStoreIcon from '../pages/images/shop-private.png';

import stockImg from '../pages/images/stock.png';

import chatImg from '../pages/images/chat.png';


const features = [
  {
    title: 'Easy Setup',
    image: easySetupImg,
    description: 'Quick and intuitive setup process for your e-store.',
  },
  {
    title: 'Customizable Stores',
    image: customizableDesignImg,
    description: 'Fully customizable stores to match your brand.',
  },
  {
    title: '24/7 Support',
    image: supportImg,
    description: 'Our team is here to help you anytime, anywhere.',
  },
  {
    title: 'No Fee',
    image: noFeeImg,
    description: 'Mallline does not take any kind of fees on your sales.',
  },
  {
    title: 'Secure Transactions',
    image: secureTransactionsImg,
    description: 'Top-notch security for all transactions and data.',
  },
  {
    title: 'Analytics Tools',
    image: analyticsToolsImg,
    description: 'Powerful tools to track and analyze your sales.',
  },
  {
    title: 'Affordable Pricing',
    image: affordablePricingImg,
    description: 'Get started for free and scale up as needed.',
  },
  {
    title: 'Adding Your Products',
    image: addProductsImg,
    description: 'Add all your products into your store for all customers.',
  },
  {
    title: 'Add Your Offers',
    image: addOffersImg,
    description: 'Add your store offers to all your customers.',
  },
  {
    title: 'Private Store',
    image: privateStoreIcon,
    description: 'Make the store private for your customers only.',
  },
  {
    title: 'Products Stock',
    image: stockImg,
    description: 'Manage and track your products stock with ease management system.',
  },
  {
    title: 'Chat Line',
    image: chatImg,
    description: 'Chat messages and connect with your customers in mallline.',
  },
];

const steps = [
  {
    icon: step1Icon,
    title: 'Step 1',
    description: 'Sign up for a free account on Mallline.',
  },
  {
    icon: step2Icon,
    title: 'Step 2',
    description: 'Add the name and brand of your store.',
  },
  {
    icon: step3Icon,
    title: 'Step 3',
    description: 'Add your store address or if it is online only.',
  },
  {
    icon: step4Icon,
    title: 'Step 4',
    description: 'Add products and set up your inventory.',
  },
  {
    icon: step5Icon,
    title: 'Step 5',
    description: 'Configure payment methods and selling options for customers.',
  },
  {
    icon: step6Icon,
    title: 'Step 6',
    description: 'Launch your e-store and start selling!',
  },
];

const subscriptionPolicies = [
  {
    title: 'Store Subscription Policies',
    icon: subscriptionIcon,
    description: "1. For a small fee, Mallline provides all sellers with their own store on the Mallline platform.\n" +
                 "2. Mallline does not charge any fees on the sales made through the store; the seller receives the full amount from their sales.\n" +
                 "3. Mallline only charges rent for the store to maintain services for all sellers and customers.\n" +
                 "4. Mallline does not impose any fees on the items or services that sellers provide to customers.\n" +
                 "5. In the event of a delay in paying the rental amount, Mallline will provide an extended deadline. During this period, the store will remain fully operational.\n" +
                 "6. If the seller fails to pay the rental price by the second deadline, Mallline will restrict the seller's access to the store. However, the store will still function for all customers. The seller won't be able to view or accept orders until the rent is paid.\n" +
                 "7. If a seller continues to neglect rent payments for more than six months, Mallline will permanently delete the store.\n" +
                 "8. If a store remains inactive for more than three months, Mallline will temporarily close it for all customers on the Mallline platform. If this inactivity persists for more than six months, Mallline will delete the store permanently.",
  },
  {
    title: 'What Does Mallline Offer Sellers For This Subscription?',
    icon: offerIcon,
    description: "For a Mallline store subscription, Mallline provides sellers with a powerful platform to showcase and sell their products or services. Sellers benefit from a dedicated storefront, access to a large customer base, and the infrastructure to process orders seamlessly. Mallline does not deduct any fees from a seller’s sales, ensuring that sellers receive the full amount of their transactions. Additionally, Mallline provides support services to keep everything running smoothly for all sellers and customers on the platform.",
  },
  {
    title: 'Sell As You Like',
    icon: sellIcon,
    description: "1. The platform imposes no restrictions on the daily sale of products, allowing sellers the freedom to sell as many items as they wish.\n" +
                 "2. The platform places no restrictions on the publication of products or offers, providing sellers with unrestricted freedom to showcase and promote their items.\n" +
                 "3. Mallline empowers sellers with access to cutting-edge technologies and more, enhancing their ability to showcase products to customers. From innovative display options to advanced marketing tools, Mallline equips sellers with the resources needed to effectively present their offerings and engage with customers.",
  },
];


const Stores = () => {
  const { t } = useTranslation();

  return (
    <div className="p-6 bg-white min-h-screen flex flex-col items-center">
      <section className="text-center mb-12">
        <h2 className="text-4xl font-semibold text-gray-700 mb-12 animate__animated animate__fadeIn animate__delay-1s">{t('Why Open E-store On Mallline?')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div 
              key={index} 
              className="bg-white p-6 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105 hover:shadow-xl"
            >
              <img 
                src={feature.image} 
                alt={feature.title} 
                className="w-full max-h-32 object-contain rounded-t-lg mb-4 transition-transform duration-300 hover:opacity-90"
              />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">{t(feature.title)}</h3>
              <p className="text-gray-600">{t(feature.description)}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="text-center mb-12">
        <h2 className="text-3xl font-semibold text-gray-700 mb-4 animate__animated animate__fadeIn animate__delay-2s">{t('How to Create Your E-store on Mallline')}</h2>
        <p className="text-gray-600 mb-8">
          {t('Follow these simple steps to set up your e-store on the Mallline platform:')}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {steps.map((step, index) => (
            <div 
              key={index} 
              className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105 hover:shadow-xl animate__animated animate__fadeIn animate__delay-1s"
            >
              <img 
                src={step.icon} 
                alt={step.title} 
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">{t(step.title)}</h3>
              <p className="text-gray-600 text-center">{t(step.description)}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="text-center">
        <h2 className="text-3xl font-semibold text-gray-700 mb-4 animate__animated animate__fadeIn animate__delay-s">{t('Store Subscription Policies')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {subscriptionPolicies.map((policy, index) => (
            <div 
              key={index} 
              className="flex flex-col items-center p-6 bg-white rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105 hover:shadow-xl animate__animated animate__fadeIn animate__delay-1s"
            >
              <img 
                src={policy.icon} 
                alt={policy.title} 
                className="w-16 h-16 mb-4"
              />
              <h3 className="text-xl font-semibold text-gray-800 mb-2">{t(policy.title)}</h3>
              <p className="text-gray-700 text-left whitespace-pre-line">{t(policy.description)}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Stores;
