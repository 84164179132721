// src/pages/PaymentSuccess.js
import React from 'react';
import logoImg from '../../components/assets/images/mallline_circle_logo.png';
import paymentPanded from '../../components/assets/images/payment-panded.png';

const PaymentPending = () => {

  const closePage = () => {
    window.close();
  };

  return (
    <div className="flex justify-center items-center h-screen p-5 bg-gradient-to-br from-gray-100 to-gray-200">
      <div className="text-center bg-white p-12 rounded-lg shadow-md max-w-md w-full transition-transform duration-300 ease-in-out hover:translate-y-[-10px] hover:shadow-lg">
        <div className="flex items-center justify-center mb-8">
          <img src={logoImg} alt="Mallline Logo" className="w-12 h-12" />
          <h2 className="text-3xl font-bold text-[#00b3d3] ml-2">Mallline | مول لاين</h2>
        </div>
        <img src={paymentPanded} alt="Payment Success" className="w-24 h-24 mx-auto mb-6" />
        <h1 className="text-2xl font-bold text-gray-800 mb-6 leading-tight">
        <div>Payment Pending</div>
        <div>قيد الانتظار</div>
        </h1>
        <p className="text-lg text-gray-600 mb-6 mt-6 leading-relaxed">
        <div>Your payment is currently being processed. You will be notified once it's completed.</div>
            <br/>
            <div>يتم معالجة دفعتك حاليًا. سيتم إخطارك بمجرد اكتمالها.</div>
        </p>
        <a href="https://mallline.org" className="inline-block py-3 px-6 rounded-full bg-blue-600 text-white text-lg font-bold transition-transform duration-300 ease-in-out hover:bg-[#00b3d3] hover:translate-y-[-5px]" onClick={closePage}>
          Close | العودة
        </a>
      </div>
    </div>
  );
};

export default PaymentPending;
