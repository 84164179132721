// src/pages/PrivacyPolicy.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();



  return (
    <div className="min-h-screen flex flex-col">
    
      
      <main className="flex-grow container mx-auto p-6 bg-white shadow-md rounded-lg mt-6">
        <h1 className="text-4xl font-semibold mb-6">{t('Mallline Privacy Policies')}</h1>
        <section className="mb-6">
          <p className="mb-4">{t('We understand the importance of your privacy...')}</p>
          <h2 className="text-2xl font-semibold mt-6 mb-4">{t('Information Collection:')}</h2>
          <p className="mb-4">{t('Mallline collects various types of information, including personal information provided by you during account creation, transactions, and interaction with Mallline services.')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mt-6 mb-4">{t('Use of Information:')}</h2>
          <p className="mb-4">{t('The information collected is utilized for providing and improving Mallline services, processing transactions, responding to your requests, and personalizing your experience.')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mt-6 mb-4">{t('Information Sharing:')}</h2>
          <p className="mb-4">{t('Mallline may share your information with third-party service providers, business partners, and other entities for specific purposes outlined in the Privacy Notice.')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mt-6 mb-4">{t('Security Measures:')}</h2>
          <p className="mb-4">{t('Mallline employs security measures to safeguard your information from unauthorized access, disclosure, alteration, and destruction.')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mt-6 mb-4">{t('Cookies and Similar Technologies:')}</h2>
          <p className="mb-4">{t('Mallline uses cookies and similar technologies to enhance your experience, analyze usage patterns, and personalize content.')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mt-6 mb-4">{t('Your Choices:')}</h2>
          <p className="mb-4">{t('Users have options to control the collection, use, and sharing of their information, including preferences for communication.')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mt-6 mb-4">{t('Children\'s Privacy:')}</h2>
          <p className="mb-4">{t('Mallline does not knowingly collect personal information from children under the age of 13 without parental consent.')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mt-6 mb-4">{t('Changes to Privacy Notice:')}</h2>
          <p className="mb-4">{t('Mallline may update its Privacy Notice, and users will be notified of significant changes.')}</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mt-6 mb-4">{t('Contact Information:')}</h2>
          <p className="mb-4">{t('For questions or concerns regarding privacy matters, you can contact Mallline through provided contact channels.')}</p>
          <p className="mb-4">{t('By using Mallline services, you agree to the terms outlined in this Privacy Notice. It is recommended to review the notice periodically for any updates.')}</p>
        </section>
      </main>
      
      

    </div>
  );
};

export default PrivacyPolicy;
