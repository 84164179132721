import React, { useState, useEffect } from 'react';
import '../../src/App.css';
import logoImg from '../components/assets/images/mallline_circle_logo.png';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';
  };

  useEffect(() => {
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <header>
      
      <div className="logo">
        <img src={logoImg} alt="Mallline Logo" className="logo-img" />
        <span className="logo-text">{t('Mallline')}</span>

      </div>
      <button className="menu-toggle" onClick={toggleSidebar}>
        ☰
      </button>
      

      <nav className={`sidebar ${isSidebarOpen ? 'show' : ''}`}>
        <button className="close-btn" onClick={toggleSidebar}>&times;</button>
        <ul>
          <li><a href="/">{t('Home')}</a></li>
          <li><a href="/store">{t('Create Store')}</a></li>
          <li><a href="/contact">{t('Contact')}</a></li>
          <li><a href="/privacy">{t('Privacy Policy')}</a></li>
          <li>
            <button id="language-toggle" onClick={() => changeLanguage(i18n.language === 'ar' ? 'en' : 'ar')}>
              {i18n.language === 'ar' ? 'English' : 'العربية'}
            </button>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
